import { FormInputConfig } from '~/types/residentManagement';

export const formConfig: FormInputConfig[] = [
  {
    id: 'optionList',
    label: 'Option list',
    isRequired: true,
    inputType: 'select',
    selectOptions: [
      { label: 'all users', value: 'allUsers' },
      { label: 'active users', value: 'activeUsers' },
      { label: 'tenants', value: 'tenants' }
    ]
  }
];
