import React, { CSSProperties } from 'react';

import { ReactComponent as CheckboxUnselected } from '~/utils/images/checkbox-unchecked.svg';
import { ReactComponent as CheckboxSelected } from '~/utils/images/checkbox-checked.svg';
import { IMultiSelectOption } from '~/helpers/interfaces';
import './MultiSelectCheckBox.css';
import Switch from 'react-ios-switch';

interface IProps {
  index: number;
  option: IMultiSelectOption;
  setSelectedOption: (value: string, isSelected: boolean) => void;
  checkTintColor?: string;
  tickColor?: string;
  enableLabelClickToSelect?: boolean;
  useSwitch?: boolean;
  styles?: CSSProperties;
  textStyle?: CSSProperties;
}
const MultiSelectCheckBox = (props: IProps) => {
  const {
    option,
    setSelectedOption,
    index,
    checkTintColor = '#FEC100',
    tickColor,
    useSwitch = false,
    styles = {},
    textStyle
  } = props;

  if (useSwitch) {
    return (
      <div
        key={index}
        style={{ color: checkTintColor, ...styles }}
        className='checkbox-row'
      >
        <Switch
          checked={option.isSelected}
          onChange={() => setSelectedOption(option.value, !option.isSelected)}
        />
        <p style={textStyle} className='margin-left-16'>
          {option.text}
        </p>
      </div>
    );
  }

  return (
    <div
      key={index}
      style={{ color: checkTintColor, ...styles }}
      className='checkbox-row'
      onClick={() => setSelectedOption(option.value, !option.isSelected)}
    >
      <div
        style={
          option.isSelected
            ? {
                backgroundColor: tickColor,
                width: '20px',
                height: '20px',
                borderRadius: '20px'
              }
            : {}
        }
      >
        {option.isSelected ? <CheckboxSelected /> : <CheckboxUnselected />}
      </div>
      <p style={textStyle} className='margin-left-16'>
        {option.text}
      </p>
    </div>
  );
};
export default MultiSelectCheckBox;
