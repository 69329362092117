import React, { CSSProperties, ReactNode } from 'react';
import { toast } from 'react-toastify';
import { Colors } from '~/enums/Colors';
import { Roles } from '~/enums/Roles';
import {
  Building,
  BuildingDashboardConfig,
  BuildingStats
} from '~/types/building';

export interface IBuildingConfigColumn {
  title: string;
  color: string;
  sibling?: IBuildingConfigColumn[];
  appendSiblingAtBottom?: boolean;
  cards: {
    selector: string;
    title: string;
    description?: string;
    statsValue?: string;
    footerLinkText?: string;
    footerLink?: string;
    renderCustomFooter?: () => ReactNode;
    titleLink?: string;
    action?: string;
    show: boolean;
    showAction?: boolean;
    styles?: { footerStyle: CSSProperties };
  }[];
}

function configureDashboardData(
  stats: BuildingStats,
  building: Building,
  currentUserRole: string,
  buildingDashboardConfig: BuildingDashboardConfig
) {
  const isSuperAdminOrVCM =
    currentUserRole === Roles.Superadmin || currentUserRole === Roles.Vcm;

  const queryParams = new URLSearchParams({
    isGlobalSurvey: 'false'
  }).toString();

  const dashboardData: IBuildingConfigColumn[] = [
    {
      title: 'USERS',
      color: Colors.cobuTurquoise,
      appendSiblingAtBottom: true,
      sibling:
        currentUserRole === Roles.Superadmin
          ? [
              {
                title: 'SURVEYS',
                color: Colors.cobuTurquoise,
                cards: [
                  {
                    selector: 'buildingSurveys',
                    title: 'Manage Surveys',
                    description:
                      'Create and view the results of survey sent to residents',
                    titleLink: `/building/${building.uuid}/questionnaires?${queryParams}`,
                    show: currentUserRole === Roles.Superadmin
                  }
                ]
              }
            ]
          : [],
      cards: [
        {
          selector: 'totalUsers',
          title: 'Total Cobu Users',
          description: ' Registered and active in the past 6 months',
          statsValue: stats.totalUsers.toString(),
          footerLinkText: `${
            stats.totalUsers === 0 ? 'Add' : 'Edit'
          } Cobu Users`,
          footerLink: `/building/${building.uuid}/residents`,
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm,
          showAction:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm
        },
        {
          selector: 'totalOccupiedUnits',
          title: 'Total Occupied Units',
          description: 'Occupied units in the building',
          statsValue: stats.totalOccupiedUnits.toString(),
          footerLinkText: `${
            stats.totalOccupiedUnits === 0 ? 'Add' : 'Edit'
          } Occupied Units`,
          action: 'showOUModal',
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm
        },
        {
          selector: 'adoptionRate',
          title: 'Adoption Rate',
          description: 'Users as percent of occupied units',
          statsValue: stats.adoptionRate.toString() + '%',
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm
        },
        {
          selector: 'totalActiveUsers',
          title: 'Total Active Users',
          description: 'Active in the past 30 days',
          statsValue: stats.totalActiveUsers.toString(),
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm
        },
        {
          selector: 'activeRate',
          title: 'Active Rate',
          description: 'Active users as % of total users',
          statsValue: stats.activeRate.toString() + '%',
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm
        },
        {
          selector: '',
          title: 'Manage Tenants',
          description: 'Tenants that needs to be move-in/out or transfered.',
          statsValue: '',
          titleLink: `/building/${building.uuid}/rentroll/upload`,
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm
        }
      ]
    },
    {
      title: 'BUILDING ACTIVITY',
      color: Colors.cobuRed,
      cards: [
        {
          selector: 'numberOfGatherings',
          title: 'Number of Gatherings',
          description: 'Logged in the past 9 months',
          statsValue: stats.gatheringsCount9Months.toString(),
          footerLinkText: 'See Gathering Details',
          footerLink: `/building/${building.uuid}/gathering`,
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm
        },
        {
          selector: 'numberOfRSVPs',
          title: 'Number of RSVPs',
          description: 'Logged in the past 30 days',
          statsValue: stats.totalRSVPs.toString(),
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm
        },
        {
          selector: 'numberOfComments',
          title: 'Number of Comments',
          description: 'Posted in the last 30 days',
          statsValue: stats.totalChatComments.toString(),
          footerLinkText: 'See All Comments',
          footerLink: `/building/${building.uuid}/comments`,
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm
        },
        {
          selector: 'scheduledComments',
          title: 'Scheduled Comments',
          description: 'Schedule Comments',
          statsValue: stats.totalScheduledComments.toString(),
          footerLinkText: 'See Scheduled Comments',
          footerLink: `/building/${building.uuid}/scheduledcomments`,
          show: true
        },
        {
          selector: 'commentsPerActiveUsers',
          title: 'Comments per Active User',
          description: 'Comments per active user in the past 30 days',
          statsValue: stats.commentsPerActiveUsers.toString(),
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm
        },
        {
          selector: 'groupsPerBuilding',
          title: 'Groups',
          description: 'Total number of groups',
          statsValue: stats.totalGroups.toString(),
          footerLinkText: 'See Groups Details',
          footerLink: `/building/${building.uuid}/groups`,
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm
        }
      ]
    },
    {
      title: 'BUILDING',
      color: Colors.cobuPurple,
      sibling: [
        {
          title: 'COBU LEADS',
          color: Colors.cobuPurple,
          cards: [
            {
              selector: 'totalLeads',
              title: 'Total Leads',
              description: 'Leads that have been referred by a resident',
              statsValue: stats?.totalLeads?.toString(),
              footerLinkText: 'See All Leads',
              footerLink: `/building/${building.uuid}/referralLeads`,
              show: !!buildingDashboardConfig.communityLead,
              styles: { footerStyle: { height: '50px' } }
            },
            ...(isSuperAdminOrVCM
              ? [
                  {
                    selector: 'createReferralOffer',
                    title: 'Create/Edit Referral Offer',
                    titleLink: `/building/${building.uuid}/createReferral`,
                    show: !!buildingDashboardConfig.communityLead
                  },
                  {
                    selector: 'editBuilding',
                    title: 'Community Lead Settings',
                    titleLink: `/building/${building.uuid}/referralSetting`,
                    show:
                      currentUserRole === Roles.Superadmin ||
                      currentUserRole === Roles.Vcm
                  }
                ]
              : []),
            {
              selector: 'referralOffer',
              title: 'Referral Offer',
              show:
                (currentUserRole === Roles.Superadmin ||
                  currentUserRole === Roles.Vcm ||
                  currentUserRole === Roles.Pm) &&
                !!stats.referOffer &&
                !!buildingDashboardConfig.communityLead,

              styles: { footerStyle: { height: '100px' } },
              statsValue: ' ',
              renderCustomFooter: () => {
                return (
                  <div>
                    <div>{stats.referOffer}</div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: 'var(--cobuLightGreyPointFive)',
                        marginTop: '4px',
                        fontWeight: 'normal'
                      }}
                    >
                      To change or pause the offer, please contact
                      customersuccess@livecobu.com.
                    </div>
                  </div>
                );
              }
            },
            {
              selector: 'employeeOffer',
              title: 'Preferred Employee Offer',
              show:
                (currentUserRole === Roles.Superadmin ||
                  currentUserRole === Roles.Vcm ||
                  currentUserRole === Roles.Pm) &&
                !!stats.employeeOffer &&
                !!buildingDashboardConfig.communityLead,
              statsValue: ' ',
              styles: { footerStyle: { height: '100px' } },
              renderCustomFooter: () => {
                return (
                  <div>
                    <div>{stats.employeeOffer}</div>
                    <div
                      style={{
                        fontSize: '14px',
                        lineHeight: '21px',
                        color: 'var(--cobuLightGreyPointFive)',
                        marginTop: '4px',
                        fontWeight: 'normal'
                      }}
                    >
                      To change or pause the offer, please contact
                      customersuccess@livecobu.com.
                    </div>
                  </div>
                );
              }
            }
          ]
        },
        ...(isSuperAdminOrVCM
          ? [
              {
                title: 'App Settings',
                color: Colors.cobuYellow,
                cards: [
                  {
                    selector: 'attributionDeeplink',
                    title: 'Deeplinks',
                    titleLink: `/building/${building.uuid}/deeplinks`,
                    show:
                      currentUserRole === Roles.Superadmin ||
                      currentUserRole === Roles.Vcm
                  }
                ]
              }
            ]
          : [])
      ],
      cards: [
        {
          selector: 'enableHelloData',
          title: 'Hello Data',
          titleLink: `/building/${building.uuid}/helloData`,
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm
        },
        {
          selector: 'neighborhoodOffers',
          title: 'Neighborhood Offers',
          titleLink: `/building/${building.uuid}/perks`,
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm
        },
        {
          selector: 'editBuilding',
          title: 'Building Settings',
          titleLink: `/building/${building.uuid}/edit`,
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm
        },
        {
          selector: 'pickCommenter',
          title: 'Building User',
          description:
            (currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm
              ? stats.VCMCommenter
              : stats.PMCommenter) ||
            'Add User to be able to comment, replay...',
          action: 'showBUModal',
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Pm
        },
        {
          selector: 'buildingReviews',
          title: 'Building Reviews',
          titleLink: `/building/${building.uuid}/reviews`,
          show:
            (currentUserRole === Roles.Pm &&
              buildingDashboardConfig.showReviewsToPM) ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Superadmin
        },
        {
          selector: 'buildingReports',
          title: 'Building Reports',
          titleLink: `/building/${building.uuid}/reports`,
          show:
            (currentUserRole === Roles.Pm &&
              buildingDashboardConfig.showReviewsToPM) ||
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Superadmin
        },
        {
          selector: 'pollsLink',
          title: 'Polls',
          action: 'openPollsLink',
          show: !!building.pollsLink
        },
        {
          selector: 'rafflesLink',
          title: 'Neighborhood Raffles',
          action: 'openRafflesLink',
          show: !!building.rafflesLink
        },
        {
          selector: 'pmsIntegration',
          title: 'PMS Integration',
          titleLink: `/building/${building.uuid}/pms-integration`,
          // renderCustomFooter: () => {
          //   return (
          //     <div
          //       style={{
          //         display: 'inline-flex',
          //         flexDirection: 'row',
          //         justifyContent: 'flex-start',
          //         alignItems: 'center',
          //         height: '100px',
          //         cursor: 'pointer',
          //         gap: '10px 10px',
          //         maxHeight: '50px'
          //       }}
          //       onClick={() => {
          //         console.log('coming here@@@@');
          //         navigator.clipboard.writeText(
          //           `${window.location.origin}/entrata-config/${building.uuid}`
          //         );

          //         toast('Link copied!');
          //       }}
          //     >
          //       <div>Entrata PMS config form link</div>
          //       <CopyIcon width={20} height={20} />
          //     </div>
          //   );
          // },
          show:
            currentUserRole === Roles.Vcm ||
            currentUserRole === Roles.Superadmin
        },
        {
          selector: 'deactivateBuildingLink',
          title: 'Deactivate Building',
          titleLink: `/building/${building.uuid}/deactivate`,
          show: currentUserRole === Roles.Superadmin
        },
        {
          selector: 'myBuildingTiles',
          title: 'My Building Tiles',
          titleLink: `/building/${building.uuid}/myBuildingTiles`,
          show:
            currentUserRole === Roles.Superadmin ||
            currentUserRole === Roles.Vcm
        }
      ]
    }
  ];
  return dashboardData;
}

export { configureDashboardData };
