import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useGetBuidingQuestionnaires from './hooks';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import Spacer from '~/components/Spacer/Spacer';
import { CellProps } from 'react-table';
import { IQuestionnaireWithResult } from '~/api/questionnair';
import Spinner from '~/components/Spinner/Spinner';
import GenericModal from '~/components/GenericModal/GenericModal';
import { FormInputConfig } from '~/types/residentManagement';
import { FormField } from '~/components/FormField';
import { useForm } from 'react-hook-form';
import { formConfig } from './constants';
import classes from './QuestionnaireList.module.scss';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';

export const QuestionnaireList = () => {
  const history = useHistory();

  const { buildingId } = useParams<{
    buildingId: string;
  }>();

  const {
    questionnairesWithResult,
    isLoading,
    showScheduleModal,
    setShowScheduleModal
  } = useGetBuidingQuestionnaires(buildingId);

  const isGlobaQuestionnaire = buildingId === 'cobu';

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isDirty }
  } = useForm<{ optionList: string }>({
    defaultValues: {}
  });

  const onCreateSurvey = () => {
    history.push(`/building/${buildingId}/questionnaires/create-edit`);
  };

  const onScheduleSend = (questionnaire: IQuestionnaireWithResult) => {
    if (questionnaire.scheduledDetails) {
      reset({}); // TODO
    }

    setShowScheduleModal(true);
    // TOOD: show list of options all users(user + tenants), active users
  };

  const onViewResults = (questionnaire: IQuestionnaireWithResult) => {};

  const onEditSurvey = (questionnaire: IQuestionnaireWithResult) => {
    const queryParams = new URLSearchParams({
      questionnaireId: questionnaire.uuid as string
    }).toString();

    history.push(
      `/building/${buildingId}/questionnaires/create-edit?${queryParams}`
    );
  };

  const columns = [
    {
      Header: 'SURVEY',
      accesor: 'name',
      Cell: (cell: CellProps<IQuestionnaireWithResult>) => {
        return <div>{cell.row.original.name}</div>;
      }
    },
    {
      Header: 'QUESTIONS',
      accesor: 'numberOfQuestions',
      Cell: (cell: CellProps<IQuestionnaireWithResult>) => {
        return <div>{cell.row.original.numberOfQuestions}</div>;
      }
    },
    {
      Header: 'RESULTS',
      accesor: 'results',
      Cell: (cell: CellProps<IQuestionnaireWithResult>) => {
        return <div>{cell.row.original.results}</div>;
      }
    },
    {
      Header: 'ACTIONS',
      accessor: '',
      Cell: (cell: CellProps<IQuestionnaireWithResult>) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0 20px'
            }}
          >
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => onEditSurvey(cell.row.original)}
            >
              Edit Survey
            </div>
            {cell.row.original.scheduledDetails && (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => onViewResults(cell.row.original)}
              >
                View Results
              </div>
            )}
            {!isGlobaQuestionnaire && (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => onScheduleSend(cell.row.original)}
              >
                {cell.row.original.scheduledDetails
                  ? 'Edit Schedule Send'
                  : 'Create Schedule Send'}
              </div>
            )}
          </div>
        );
      }
    }
  ];

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const onRescheduleSubmit = () => {
    //TODO: Shivam handled create and edit flow

    if (isDirty) {
      // edit flow
      return;
    }

    //create flow
  };

  const renderScheduleForm = () => {
    return (
      <div className='float-spinner-parent'>
        <form id='form-container' onSubmit={handleSubmit(onRescheduleSubmit)}>
          <div>{formConfig.map((field) => generateField(field))}</div>
          <Spacer height={20} />
          <div className='formButtons'>
            {isDirty && (
              <button
                className='btn-standard btn-secondary'
                onClick={(event) => {
                  event.preventDefault();
                  reset();
                }}
              >
                {'Reset'}
              </button>
            )}
            <button
              className='btn-standard btn-secondary margin-left-16'
              onClick={(event) => {
                event.preventDefault();

                setShowScheduleModal(false);
              }}
            >
              {'Cancel'}
            </button>
            <button
              className='btn-standard btn-primary margin-left-16 '
              type='submit'
            >
              {isDirty ? 'Update' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className={`'float-spinner-parent' ${classes.container}`}>
      <Breadcrumbs {...(isGlobaQuestionnaire ? { indexesToHide: [1] } : {})} />
      <Spacer height={20} />
      <h2>Survey List</h2>
      {isLoading && <Spinner float />}
      {showScheduleModal && (
        <GenericModal
          showModal={showScheduleModal}
          title={'Configure schedule time'}
          enableOverflowScrolls
          content={renderScheduleForm()}
          hideActionButton
          canceActionButton
          actionText={''}
          actionFunction={() => {}}
          onChange={() => {}}
        />
      )}
      <CobuTableAdvanced
        searchSibling={
          <div className={classes.createSurvey}>
            <button
              className='btn-standard btn-primary'
              onClick={onCreateSurvey}
            >
              {isGlobaQuestionnaire
                ? 'Create cobu survey'
                : 'Create building wide survey'}
            </button>
          </div>
        }
        enableDeferredSearch
        centerAlignedHeaderAndContent
        noData={{
          label: 'No Data',
          action: undefined,
          image: undefined
        }}
        actionsStyle={{
          position: 'static',
          justifyContent: 'flex-end',
          marginBottom: '10px'
        }}
        search={{
          searchField: 'name',
          placeholder: 'Questionnaire Name'
        }}
        columns={columns}
        data={questionnairesWithResult}
        sort={{
          id: 'createdAt',
          descending: true
        }}
      />
    </div>
  );
};
