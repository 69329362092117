import React, { FC } from 'react';
import { FormInputConfig } from '~/types/residentManagement';
import { FormField } from '~/components/FormField';
import { useForm } from 'react-hook-form';
import GenericModal from '~/components/GenericModal/GenericModal';
import Spacer from '~/components/Spacer/Spacer';
import { IQuestionTypeAndSubType } from '~/api/questionnair';
import { QuestionsTypes } from '../constants';
import { IQuestionnairTableQuestion } from './interfaces';

interface IProps {
  selectedQuestion?: IQuestionnairTableQuestion | null;
  onFormSubmit: (question: IQuestionnairTableQuestion) => void;
  onCancel: () => void;
  showFormModal: boolean;
  questionTypeAndSubTypes: IQuestionTypeAndSubType[];
  modalTitle: string;
}

export const CreateQuestionsForm: FC<IProps> = ({
  selectedQuestion = null,
  onFormSubmit,
  onCancel,
  showFormModal,
  questionTypeAndSubTypes,
  modalTitle
}) => {
  const { register, handleSubmit, errors, control, reset, watch } =
    useForm<IQuestionnairTableQuestion>({
      defaultValues: selectedQuestion ?? {}
    });

  const selectedType: QuestionsTypes | undefined = watch('type');

  const questionTypeWithSubType: IQuestionTypeAndSubType | undefined =
    questionTypeAndSubTypes.find(
      (type) => type.value === selectedType?.toString()
    );

  const formConfig: FormInputConfig[] = [
    {
      id: 'id',
      displayNone: true,
      isRequired: false
    },
    {
      id: 'question' as keyof IQuestionnairTableQuestion,
      isRequired: true,
      placeholder: 'eg: Marketplace',
      label: 'Title'
    },
    {
      id: 'type' as keyof IQuestionnairTableQuestion,
      label: 'Type',
      isRequired: true,
      inputType: 'select',
      selectOptions: questionTypeAndSubTypes
    },
    ...(questionTypeWithSubType?.subTypes
      ? [
          {
            id: 'subType' as keyof IQuestionnairTableQuestion,
            label: 'Sub Type',
            isRequired: true,
            inputType: 'select',
            selectOptions: questionTypeWithSubType?.subTypes
          } as FormInputConfig
        ]
      : [])
  ];

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className='float-spinner-parent'>
        <form id='form-container' onSubmit={handleSubmit(onFormSubmit)}>
          <div>{formConfig.map((field) => generateField(field))}</div>
          <Spacer height={20} />
          <div className='formButtons'>
            {!!selectedQuestion && (
              <button
                className='btn-standard btn-secondary'
                onClick={(event) => {
                  event.preventDefault();
                  reset();
                }}
              >
                {'Reset'}
              </button>
            )}
            <button
              className='btn-standard btn-secondary margin-left-16'
              onClick={(event) => {
                event.preventDefault();

                onCancel();
              }}
            >
              {'Cancel'}
            </button>
            <button
              className='btn-standard btn-primary margin-left-16 '
              type='submit'
            >
              {selectedQuestion ? 'Update' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <GenericModal
      showModal={showFormModal}
      title={modalTitle}
      enableOverflowScrolls
      content={renderForm()}
      hideActionButton
      canceActionButton
      actionText={''}
      actionFunction={() => {}}
      onChange={() => {}}
    />
  );
};
