import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getBuildingQuestionnaireWithResults,
  IQuestionnaireWithResult
} from '~/api/questionnair';
import { Roles } from '~/enums/Roles';
import { RootState } from '~/redux/store';

export default (buildingId: string) => {
  const [questionnairesWithResult, setQuestionnairesWithResult] = useState<
    IQuestionnaireWithResult[]
  >([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);

  const fetchBuildingQuestionnaire = async () => {
    try {
      setLoading(true);
      const { data } = await getBuildingQuestionnaireWithResults(buildingId);

      setQuestionnairesWithResult(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBuildingQuestionnaire();
  }, []);

  return {
    questionnairesWithResult,
    isLoading,
    showScheduleModal,
    setShowScheduleModal
  };
};
