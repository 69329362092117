import React from "react";
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs";
import Spacer from "~/components/Spacer/Spacer";
import CobuConstants from "~/helpers/cobuConstants";
import "./Audits.css"; 
import { useHistory } from "react-router-dom";

interface TileProps {
    titleLine1: string;
    titleLine2: string;
    description: string;
    onPress?: () => void;
}

const Tile: React.FC<TileProps> = ({ titleLine1, titleLine2, description, onPress }) => {
    return (
        <div className="tile-container" onClick={onPress}>
            <div className="title-container">
                <span className="title-line1">{titleLine1}</span>
                <br />
                <span className="title-line2">{titleLine2}</span>
            </div>
            <Spacer height={20} />
            <div className="description">{description}</div>
            <Spacer height={30} />
        </div>
    );
};

const MemoizedTile = React.memo(Tile);

const Audits = () => {
    
    const history = useHistory();
    const tiles = CobuConstants.audits.tiles;

    const handleNavigation = (path: string) => {
        history.push(path);
    };

    return (
        <div className="content-container">
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>{CobuConstants.audits.pageTitle}</h1>
            <Spacer height={20} />
            <div className="tiles-container">
                {tiles.map((tile: { titleLine1: string; titleLine2: string; description: string; path: string }, index: number) => (
                    <React.Fragment key={index}>
                        <MemoizedTile
                            titleLine1={tile.titleLine1}
                            titleLine2={tile.titleLine2}
                            description={tile.description}
                            onPress={() => handleNavigation(tile.path)}
                        />
                        {index < tiles.length - 1 && <Spacer width={50} />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default Audits;