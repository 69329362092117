import { emperor } from '~/services';

export const fetchGroups = async (buildingIds: string[]) => {
  return await emperor.get(`group/`, {
    params: { building: buildingIds, sort: 'name ASC' }
  });
};

export const fetchGroupComments = async (groupId: string, page: number) => {
  return await emperor.get(`/admin/groupcomment/group/${groupId}`, {
    params: { page }
  });
};
