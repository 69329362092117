import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getLeadsAudit } from "~/api/audits";
import CobuConstants from "~/helpers/cobuConstants";
import { LeadsAudit } from "~/types/audit";

interface LoadingConfig {
    flag: boolean;
    comment?: string;
}

const useLeadAudit = (isLeads: boolean = true) => {
    const [leadsAuditDetails, setLeadsAuditDetails] = useState<LeadsAudit[]>([]);
    const [loadingConfig, setLoadingConfig] = useState<LoadingConfig>({
        flag: false,
        comment: "",
    });

    useEffect(() => {
        fetchAuditDetails();
    }, []);

    const fetchAuditDetails = async (refresh: boolean = false) => {
        setLoadingConfig({
            flag: true,
            comment: refresh
                ? CobuConstants.audits.refreshingAuditDetails
                : CobuConstants.audits.fetchingAuditDetails,
        });

        try {
            const auditResp = await getLeadsAudit();
            setLeadsAuditDetails(auditResp.data.reverse());
            setLoadingConfig({ flag: false });
        } catch (error: any) {
            setLoadingConfig({ flag: false });
            toast(error?.message || CobuConstants.audits.fetchError);
        }
    };

    return {
        leadsAuditDetails,
        loadingConfig,
        fetchAuditDetails,
    };
};

export default useLeadAudit;