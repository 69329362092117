import React, { HTMLAttributes, MouseEventHandler, useMemo } from 'react';
import {
  Column,
  RowPropGetter,
  TableRowProps,
  useSortBy,
  useTable,
  usePagination
} from 'react-table';
import { Colors } from '~/enums/Colors';
import './CobuTable.css';

interface CobuTableProps {
  columns: Column<any>[];
  data: {}[];
  sort?: {
    id: string;
    descending: boolean;
  };
  hideRightBorder?: boolean;
  centerAlignedHeaderAndContent?: boolean;
  height?: number;
  hideHeader?: boolean;
  mini?: boolean;
  showPagination?: boolean;
  paginationPageSize?: number;
  cellTextAlign?:
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start';
  rowProps?: {
    onMouseEnter: (value: any) => void;
    onMouseLeave: (value: any) => void;
  };
  ignoreDefaultSortBehaviour?: boolean;
}

const CobuTable = (props: CobuTableProps) => {
  const {
    data,
    columns,
    sort,
    hideRightBorder,
    showPagination,
    paginationPageSize = 10,
    cellTextAlign = 'left',
    centerAlignedHeaderAndContent = false,
    ignoreDefaultSortBehaviour = false
  } = props;

  const sortBy = useMemo(
    () => [
      {
        id: sort?.id || '',
        desc: sort?.descending || false
      }
    ],
    [sort]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        ...(!ignoreDefaultSortBehaviour && {
          sortBy: sortBy
        }),
        pageSize: paginationPageSize,
        pageIndex: 0
      }
    },
    useSortBy,
    usePagination
  );
  const columnsWithoutSort = [
    'ROLE',
    'USER NAME',
    'SCORE',
    '# OF POSTS',
    'RSVPs',
    'LAST ACTIVITY',
    'NAME ON REVIEW',
    'REVIEW TEXT',
    'AVG RATING',
    'GROUP',
    'FEEDBACK RESPONSES',
    'GATHERING NAME',
    'HOW TO',
    'RECOMMENDED MONTH',
    'PHONE',
    'UNIT NUMBER',
    'SENTIMENT SCORE',
    'INTERCOM',
    'ONESIGNAL',
    ''
  ];

  return (
    <div
      className='tableContainer'
      style={
        props.height
          ? {
              overflow: 'scroll',
              height: props.height
            }
          : {}
      }
    >
      <table
        {...getTableProps()}
        style={{
          ...(showPagination
            ? {}
            : { marginBottom: props.height ? '0px' : '50px' }),
          ...(props.height ? { height: '100%' } : {}),
          width: '100%',
          borderRight: hideRightBorder
            ? 'none'
            : 'solid 2px rgba(39, 33, 99, 0.1)',
          borderTopLeftRadius: '12px',
          borderBottomLeftRadius: '12px',
          borderTopRightRadius: hideRightBorder ? '0px' : '12px',
          borderBottomRightRadius: hideRightBorder ? '0px' : '12px'
        }}
      >
        {props.hideHeader ? null : (
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} className={ (headerGroups.length > 1 && index === 0 ) ? "main-header" : "sub-header"} >
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        borderBottom: 'solid 1px rgba(39, 33, 99, 0.1)',
                        padding: '24px 10px',
                        color: 'rgba(39, 33, 99, 0.5)',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        textAlign: 'left'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          cursor: 'pointer',
                          alignItems: 'center',
                          justifyContent: (headerGroups.length > 1 && index === 0) ? "center" : "flex-start",
                          ...(centerAlignedHeaderAndContent
                            ? { justifyContent: 'center' }
                            : {})
                        }}
                      >
                        {column.render('Header')}
                        {typeof column.Header === 'string' &&
                          !columnsWithoutSort.includes(column.Header) &&
                          !column.disableSortBy && (
                            <div
                              style={{
                                position: 'relative',
                                fontSize: '10px',
                                marginLeft: '10px'
                              }}
                            >
                              {((column.isSorted && !column.isSortedDesc) ||
                                !column.isSorted) && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    bottom: '-3px'
                                  }}
                                >
                                  ▲
                                </div>
                              )}
                              {((column.isSorted && column.isSortedDesc) ||
                                !column.isSorted) && (
                                <div
                                  style={{ position: 'absolute', top: '-1px' }}
                                >
                                  ▼
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {showPagination
            ? page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    onMouseEnter={() => {
                      if (props.rowProps?.onMouseEnter)
                        props.rowProps?.onMouseEnter(row.original);
                    }}
                    onMouseLeave={() => {
                      if (props.rowProps?.onMouseLeave)
                        props.rowProps?.onMouseLeave(row.original);
                    }}
                    {...row.getRowProps()}
                    className='row'
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: props.mini ? '' : '24px 10px',
                            fontFamily: 'Roboto',
                            color: Colors.cobuDarkBlue,
                            fontWeight: 500,
                            fontSize: '18px',
                            textAlign: centerAlignedHeaderAndContent
                              ? 'center'
                              : cellTextAlign,
                            whiteSpace: 'nowrap',
                            maxWidth: '300px',
                            borderBottom: 'solid 1px rgba(39, 33, 99, 0.1)'
                          }}
                        >
                          {centerAlignedHeaderAndContent ? (
                            <div style={{ display: 'inline-block' }}>
                              {cell.render('Cell')}
                            </div>
                          ) : (
                            <>{cell.render('Cell')}</>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            : rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr
                    onMouseEnter={() => {
                      if (props.rowProps?.onMouseEnter)
                        props.rowProps?.onMouseEnter(row.original);
                    }}
                    onMouseLeave={() => {
                      if (props.rowProps?.onMouseLeave)
                        props.rowProps?.onMouseLeave(row.original);
                    }}
                    {...row.getRowProps()}
                    className='row'
                  >
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: props.mini ? '' : '24px 10px',
                            fontFamily: 'Roboto',
                            color: Colors.cobuDarkBlue,
                            fontWeight: 500,
                            fontSize: '18px',
                            textAlign: centerAlignedHeaderAndContent
                              ? 'center'
                              : cellTextAlign,
                            whiteSpace: 'nowrap',
                            maxWidth: '300px', 
                          }}
                        >
                          {centerAlignedHeaderAndContent ? (
                            <div style={{ display: 'inline-block' }}>
                              {cell.render('Cell')}
                            </div>
                          ) : (
                            <>{cell.render('Cell')}</>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
        </tbody>
      </table>
      {showPagination && (
        <div style={{ marginBottom: props.height ? '0px' : '50px' }}>
          <div className='pagination'>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {'>>'}
            </button>
            <span style={{ marginRight: '10px', marginLeft: '10px' }}>
              Page
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </div>
          <div className='pagination'>
            <span>
              Go to page:
              <input
                type='number'
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{
                  width: '100px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              />
            </span>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default CobuTable;
