import React from "react";
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs";
import Spacer from "~/components/Spacer/Spacer";
import Spinner from "~/components/Spinner/Spinner";
import useConfigAudits from "~/hooks/audits/useConfigAudits";
import CobuConstants from "~/helpers/cobuConstants";
import CobuTableAdvanced from "~/components/CobuTableAdvanced/CobuTableAdvanced";
import BooleanBox from "~/components/BooleanBox/BooleanBox";
import WrappableText from "~/components/CollapsibleText/WrappableText";
import moment from "moment";
import { CellProps } from "react-table";
import { ConfigAudit } from "~/types/audit";
import styles from "../../ActiveSurveys/ActiveSurvey.module.css";

const ConfigAudits: React.FC = () => {
    const { loadingConfig, configAuditDetails } = useConfigAudits();

    if (loadingConfig.flag) {
        return (
            <div className={styles.loadingContainer}>
                <Spinner />
                <h3 style={{ textAlign: "center" }}>{loadingConfig.comment}</h3>
            </div>
        );
    }

    const columns = [
        {
            Header: CobuConstants.configAudits.headers.buildingGroupHeader,
            id: 'building',
            disableSortBy: true,
            columns: [
                {
                    Header: CobuConstants.configAudits.headers.building,
                    accessor: CobuConstants.configAudits.fields.name,
                    disableSortBy: true,
                    Cell: (cell: CellProps<ConfigAudit>) => (
                        <WrappableText text={cell.value || CobuConstants.shared.defaultPlaceholder} />
                    ),
                },
                {
                    Header: CobuConstants.configAudits.headers.created,
                    accessor: CobuConstants.configAudits.fields.createdAt,
                    disableSortBy: true,
                    Cell: (cell: CellProps<ConfigAudit>) => (
                        <div>{moment(cell.value).format(CobuConstants.dateFormats.mmddyyyy)}</div>
                    ),
                },
                {
                    Header: CobuConstants.configAudits.headers.pms,
                    accessor: CobuConstants.configAudits.fields.pms,
                    disableSortBy: true,
                    Cell: (cell: CellProps<ConfigAudit>) => (
                        <WrappableText text={cell.value || CobuConstants.shared.defaultPlaceholder} />
                    ),
                },
            ],
        },
        {
            Header: CobuConstants.configAudits.headers.initial,
            disableSortBy: true,
            columns: [
                {
                    Header: CobuConstants.configAudits.headers.emails,
                    accessor: CobuConstants.configAudits.fields.emailsConfigured,
                    disableSortBy: true,
                    Cell: ({ value }: CellProps<ConfigAudit>) => <BooleanBox value={value} />,
                },
                {
                    Header: CobuConstants.configAudits.headers.drive,
                    accessor: CobuConstants.configAudits.fields.driveEnabled,
                    disableSortBy: true,
                    Cell: ({ value }: CellProps<ConfigAudit>) => <BooleanBox value={value} />,
                },
            ],
        },
        {
            Header: CobuConstants.configAudits.headers.engagement,
            disableSortBy: true,
            columns: [
                {
                    Header: CobuConstants.configAudits.headers.pmsConfigured,
                    accessor: CobuConstants.configAudits.fields.pmsConfigured,
                    disableSortBy: true,
                    Cell: ({ value }: CellProps<ConfigAudit>) => <BooleanBox value={value} />,
                },
                {
                    Header: CobuConstants.configAudits.headers.rentRoll,
                    accessor: CobuConstants.configAudits.fields.rentRollDate,
                    disableSortBy: true,
                    Cell: (cell: CellProps<ConfigAudit>) => (
                        <div>{cell.value ? moment(cell.value).format(CobuConstants.dateFormats.mmddyyyy) : CobuConstants.shared.defaultPlaceholder}</div>
                    ),
                },
            ],
        },
        {
            Header: CobuConstants.configAudits.headers.reviews,
            disableSortBy: true,
            columns: [
                {
                    Header: CobuConstants.configAudits.headers.url,
                    accessor: CobuConstants.configAudits.fields.reviewUrl,
                    disableSortBy: true,
                    Cell: ({ value }: CellProps<ConfigAudit>) => <BooleanBox value={value} />,
                },
                {
                    Header: CobuConstants.configAudits.headers.results,
                    accessor: CobuConstants.configAudits.fields.reviewResult,
                    disableSortBy: true,
                    Cell: ({ value }: CellProps<ConfigAudit>) => <BooleanBox value={value} />,
                },
                {
                    Header: CobuConstants.configAudits.headers.events,
                    accessor: CobuConstants.configAudits.fields.event,
                    disableSortBy: true,
                    Cell: ({ value }: CellProps<ConfigAudit>) => <BooleanBox value={value} />,
                },
                {
                    Header: CobuConstants.configAudits.headers.cobu,
                    accessor: CobuConstants.configAudits.fields.cobuReviews,
                    disableSortBy: true,
                },
            ],
        },
        {
            Header: CobuConstants.configAudits.headers.leads,
            disableSortBy: true,
            columns: [
                {
                    Header: CobuConstants.configAudits.headers.referral,
                    accessor: CobuConstants.configAudits.fields.referral,
                    disableSortBy: true,
                    Cell: ({ value }: CellProps<ConfigAudit>) => <BooleanBox value={value} />,
                },
                {
                    Header: CobuConstants.configAudits.headers.pep,
                    accessor: CobuConstants.configAudits.fields.isPepEnabled,
                    disableSortBy: true,
                    Cell: ({ value }: CellProps<ConfigAudit>) => <BooleanBox value={value} />,
                },
            ],
        },
    ];

    return (
        <div className= "content-container">
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>{CobuConstants.configAudits.pageTitle}</h1>
            <Spacer height={20} />
            <CobuTableAdvanced
                columns={columns}
                data={configAuditDetails}
                noData={{
                    label: CobuConstants.configAudits.noDataPlaceholder,
                    action: undefined,
                    image: undefined,
                }}
                actionsStyle={{ top: "150px" }}
                search={{
                    searchField: CobuConstants.configAudits.searchField,
                    placeholder: CobuConstants.configAudits.searchPlaceholder,
                }}
            />
        </div>
    );
};

export default ConfigAudits;