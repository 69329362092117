import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Column, useSortBy, useTable, usePagination } from 'react-table';
import './CobuTable.css';
import { DragContext } from './DragContext';
import { DragSortableContext } from './DragSortableContext';
import { TableRow } from './TableRow';
import { arrayMove } from '@dnd-kit/sortable';
import { TableHead } from './TableHead';
import { v4 as uuidv4 } from 'uuid';

export interface CobuTableProps {
  columns: Column<any>[];
  data: {}[];
  sort?: {
    id: string;
    descending: boolean;
  };
  hideRightBorder?: boolean;
  centerAlignedHeaderAndContent?: boolean;
  height?: number;
  hideHeader?: boolean;
  mini?: boolean;
  showPagination?: boolean;
  paginationPageSize?: number;
  cellTextAlign?:
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start';
  rowProps?: {
    onMouseEnter: (value: any) => void;
    onMouseLeave: (value: any) => void;
  };
  ignoreDefaultSortBehaviour?: boolean;
  isDraggable?: boolean;
  onDragEnd?: (data: Record<any, any>[]) => void;
  dragTimeout?: number;
}

const DraggableCobuTable = (props: CobuTableProps) => {
  const {
    data,
    columns,
    sort,
    hideRightBorder,
    showPagination = false,
    paginationPageSize = 10,
    cellTextAlign = 'left',
    centerAlignedHeaderAndContent = false,
    ignoreDefaultSortBehaviour = false,
    isDraggable = false,
    mini = false,
    onDragEnd,
    dragTimeout = 3000
  } = props;

  const isMounted = useRef(false);
  const dargTimeoutId = useRef<null | any>(null);

  const getIdentifiableData = useCallback(() => {
    return data.map((row) => ({ id: uuidv4(), ...row }));
  }, [data]);

  const [idetifiableData, setIdetifiableData] = useState(getIdentifiableData);

  const items = useMemo(
    () => idetifiableData.map(({ id }) => id),
    [idetifiableData]
  );

  useEffect(() => {
    if (isMounted.current) {
      setIdetifiableData(getIdentifiableData);
    } else {
      isMounted.current = true;
    }
  }, [data]);

  const setItems = (activeId: string, overId: string) => {
    const oldIndex = items.indexOf(activeId);
    const newIndex = items.indexOf(overId);

    const updatedData = arrayMove(idetifiableData, oldIndex, newIndex);
    setIdetifiableData(updatedData);
    if (onDragEnd) {
      if (dargTimeoutId.current) {
        clearTimeout(dargTimeoutId.current);
      }

      dargTimeoutId.current = setTimeout(
        () => onDragEnd(updatedData),
        dragTimeout
      );
    }
  };

  const sortBy = useMemo(
    () => [
      {
        id: sort?.id || '',
        desc: sort?.descending || false
      }
    ],
    [sort]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable<Record<any, any>>(
    {
      columns,
      data: idetifiableData,
      initialState: {
        ...(!ignoreDefaultSortBehaviour && {
          sortBy: sortBy
        }),
        pageSize: paginationPageSize,
        pageIndex: 0
      }
    },
    useSortBy,
    usePagination
  );

  return (
    <div
      className='tableContainer'
      style={
        props.height
          ? {
              overflow: 'scroll',
              height: props.height
            }
          : {}
      }
    >
      <DragContext isDraggable={isDraggable} setItems={setItems}>
        <table
          {...getTableProps()}
          style={{
            ...(showPagination
              ? {}
              : { marginBottom: props.height ? '0px' : '50px' }),
            ...(props.height ? { height: '100%' } : {}),
            width: '100%',
            borderRight: hideRightBorder
              ? 'none'
              : 'solid 2px rgba(39, 33, 99, 0.1)',
            borderTopLeftRadius: '12px',
            borderBottomLeftRadius: '12px',
            borderTopRightRadius: hideRightBorder ? '0px' : '12px',
            borderBottomRightRadius: hideRightBorder ? '0px' : '12px'
          }}
        >
          {props.hideHeader ? null : (
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {isDraggable && (
                    <TableHead
                      centerAlignedHeaderAndContent={
                        centerAlignedHeaderAndContent
                      }
                    >
                      {''}
                    </TableHead>
                  )}
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableHead
                        column={column}
                        centerAlignedHeaderAndContent={
                          centerAlignedHeaderAndContent
                        }
                      />
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}
          <DragSortableContext isDraggable={isDraggable} items={items}>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <TableRow
                    isDraggable={isDraggable}
                    key={row.original.id}
                    row={row}
                    onMouseEnter={() =>
                      props.rowProps?.onMouseEnter(row.original)
                    }
                    onMouseLeave={() =>
                      props.rowProps?.onMouseLeave(row.original)
                    }
                    mini={mini}
                    showPagination={showPagination}
                    centerAlignedHeaderAndContent={
                      centerAlignedHeaderAndContent
                    }
                    cellTextAlign={cellTextAlign}
                  />
                );
              })}
            </tbody>
          </DragSortableContext>
        </table>
      </DragContext>
      {showPagination && (
        <div style={{ marginBottom: props.height ? '0px' : '50px' }}>
          <div className='pagination'>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {'>>'}
            </button>
            <span style={{ marginRight: '10px', marginLeft: '10px' }}>
              Page
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </div>
          <div className='pagination'>
            <span>
              Go to page:
              <input
                type='number'
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{
                  width: '100px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              />
            </span>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default DraggableCobuTable;
