import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import MultiSelect from '../MultiSelect/MultiSelect';
import Spacer from '../Spacer/Spacer';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import { CreateCommentBody, ScheduledComment } from '~/types/scheduledComment';
import CobuConstants from '~/helpers/cobuConstants';
import { SelectType } from '~/enums/SelectType';
import moment from 'moment';
import { Building } from '~/types/building';

type ScheduleCommentModalProps = {
  cancel: () => void;
  existingImage?: string;
  createComment: (comment: CreateCommentBody) => void;
  onEditcomment: (comment: CreateCommentBody) => void;
  commentToEdit?: ScheduledComment | null;
  groups: any[];
  selectedBuilding: Building;
};

const ScheduleCommentModal: React.FC<ScheduleCommentModalProps> = ({
  cancel,
  createComment,
  onEditcomment,
  commentToEdit,
  groups,
  existingImage,
  selectedBuilding
}) => {
  console.log(commentToEdit, 'commentToEdit@@');

  const { register, handleSubmit, errors } = useForm<CreateCommentBody>({
    defaultValues: commentToEdit
      ? {
          building: selectedBuilding.name,
          group: commentToEdit.group?.uuid,
          postTime: moment(commentToEdit.postTime)
            .tz(selectedBuilding.timezone)
            .format('YYYY-MM-DDTHH:mm'),
          creatorEmail: commentToEdit.user.email,
          contents: commentToEdit.contents
        }
      : {
          building: selectedBuilding.name,
          group: '',
          contents: '',
          postTime: '',
          creatorEmail: '',
          image: ''
        }
  });
  const [image, setImage] = useState<File | string | undefined>(
    existingImage ?? ''
  );

  const [multiGroupValue, setMultiGroupValue] = useState(
    commentToEdit
      ? commentToEdit?.group
        ? [
            {
              value: commentToEdit.group?.uuid,
              label: commentToEdit.group?.name
            }
          ]
        : [{ value: 'null', label: 'Building Chat' }]
      : []
  );

  const onSubmit: SubmitHandler<CreateCommentBody> = (data) => {
    if (multiGroupValue.length === 0) {
      toast(CobuConstants.scheduleComment.groupNotSelectedWarning);
      return;
    }
    const group = multiGroupValue[0].value;
    commentToEdit
      ? onEditcomment({
          ...data,
          group,
          image
        })
      : createComment({
          ...data,
          group,
          image
        });
  };

  const getGroupsForDropdown = () =>
    groups?.map((group) => ({
      value: group.uuid,
      label: group.name
    }));

  const handleDrop = (acceptedFiles: File[]) => {
    setImage(acceptedFiles[0]);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='gridContainer'>
          <div>
            <label>{CobuConstants.scheduleComment.buildingLabel}</label>
            <input
              type='text'
              value={selectedBuilding.name}
              readOnly
              style={{ width: '100%', height: 45 }}
              className='non-editable-input'
            />
          </div>
          <div>
            <label htmlFor='title'>
              {CobuConstants.scheduleComment.groupLabel}
            </label>
            <Spacer height={8} />
            <MultiSelect
              singleSelect
              options={getGroupsForDropdown()}
              selectType={SelectType.Form}
              //@ts-ignore
              multiValue={multiGroupValue ?? [{ label: '', value: '' }]}
              setMultiValue={setMultiGroupValue}
              placeholder={CobuConstants.scheduleComment.selectGroupPlaceholder}
              cobuPlusBuildings={[]}
            />
          </div>
          <TextField
            style={{ marginTop: '12px' }}
            name='creatorEmail'
            label={CobuConstants.scheduleComment.creatorEmailLabel}
            inputRef={register({ required: true })}
            error={!!errors.creatorEmail}
            helperText={
              errors.creatorEmail && CobuConstants.errors.invalidEmail
            }
            fullWidth
            type='email'
          />
          <div>
            <label
              htmlFor='postTime'
              style={{ display: 'block', marginBottom: '8px' }}
            >
              {CobuConstants.scheduleComment.postTimeLabel}
            </label>
            <input
              id='postTime'
              name='postTime'
              type='datetime-local'
              style={{ width: '100%', height: 45 }}
              ref={register({
                required: true,
                validate: (value) => {
                  if (new Date(value) <= new Date()) {
                    return CobuConstants.errors.futureDateRequired;
                  }
                  return true;
                }
              })}
              className={`input-field ${errors.postTime ? 'input-error' : ''}`}
            />
            {errors.postTime && (
              <span className='error-message'>
                {errors.postTime.message || CobuConstants.errors.requiredField}
              </span>
            )}
          </div>
        </div>
        <Spacer height={20} />
        <TextField
          name='contents'
          label={CobuConstants.scheduleComment.commentContentsLabel}
          inputRef={register({ required: true })}
          error={!!errors.contents}
          helperText={errors.contents && CobuConstants.errors.requiredField}
          fullWidth
          multiline
        />
        <Spacer height={20} />
        <ImageDropzone image={image} handleDrop={handleDrop} />
        <Spacer height={20} />
        <div className='formButtons'>
          <button className='btn-standard btn-secondary' onClick={cancel}>
            {CobuConstants.createResident.cancel}
          </button>
          <input
            className='btn-standard btn-primary margin-left-16 '
            type='submit'
          />
        </div>
      </form>
    </div>
  );
};

export default ScheduleCommentModal;
