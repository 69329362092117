import React, { useState, useEffect, CSSProperties, ReactNode } from 'react';

import classes from './StatsBox.module.css';
import { ReactComponent as LongRightArrow } from '~/utils/images/long-right-arrow.svg';
import { useHistory } from 'react-router-dom';
import GenericModal from '~/components/GenericModal/GenericModal';
import { Building } from '~/types/building';
import { setCommenterRequest } from '~/api/building';
import { setCommenterSuccess } from '~/reducers/building';
import { connect, ResolveThunks } from 'react-redux';
import { toast } from 'react-toastify';
import { Roles } from '~/enums/Roles';
import { RootState } from '~/redux/store';
import CobuSelect from '~/components/CobuSelect/CobuSelect';
import { SelectType } from '~/enums/SelectType';
import { SelectedOptionType } from '~/types/form';
import CircularProgressBar from '../CircularProgressBar/CircularProgressBar';

type Props = {
  title: string;
  description?: string;
  statsValue?: string;
  color: string;
  footerLinkText?: string;
  footerLink?: string;
  renderCustomFooter?: () => ReactNode;
  titleLink?: string;
  action?: string;
  building: Building;
  currentUserRole: string;
  show: boolean;
  showActions?: boolean | undefined;
  buildingUserOptions?: SelectedOptionType[];
  changeStats?: (email: string) => void;
  updateOccupiedUnits?: (occupiedUnits: number) => void;
  isSentiment?: boolean;
  styles?: {
    footerStyle: CSSProperties;
  };
} & ResolveThunks<DispatchProps>;

interface DispatchProps {
  setCommenterSuccess: typeof setCommenterSuccess;
}
type ComponentProps = Props & ReturnType<typeof mapStateToProps>;

const StatsBox = (props: ComponentProps) => {
  const {
    title,
    description,
    statsValue,
    color,
    footerLinkText,
    footerLink,
    renderCustomFooter,
    titleLink,
    action,
    building,
    currentUserRole,
    setCommenterSuccess,
    changeStats,
    show,
    showActions,
    buildingUserOptions,
    updateOccupiedUnits,
    isSentiment,
    styles
  } = props;

  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [totalOccupiedUnits, setTotalOccupiedUnits] = useState(statsValue);
  const [disableActionButton, setDisableActionButton] = useState(true);

  const [error, setError] = useState(false);

  useEffect(() => {
    setEmail(
      currentUserRole === Roles.Superadmin || currentUserRole === Roles.Vcm
        ? building.VCMCommenter
        : building.PMCommenter
    );
  }, []);
  const updateCommenter = () => {
    setCommenterRequest(
      building.uuid,
      email,
      currentUserRole === Roles.Superadmin || currentUserRole === Roles.Vcm
        ? Roles.Vcm
        : Roles.Pm
    )
      .then((res) => {
        setCommenterSuccess({
          building: building.uuid,
          email: res.data,
          type: currentUserRole
        });
        setEmail(res.data.email);
        changeStats && changeStats(res.data);
        setShowModal(false);
        setDisableActionButton(true);
        if (res.status === 200) {
          toast('New commenter has been set!');
        }
      })
      .catch((e) => {
        toast('Something went wrong! Please try again');
      });
  };
  const renderValueField = () => {
    if (statsValue && isSentiment) {
      return <CircularProgressBar value={Number(statsValue)} color={color} />;
    } else if (statsValue) {
      return statsValue.trim() ? <div className={classes.numberBox}>{statsValue}</div> : null;
    } else {
      return <LongRightArrow />;
    }
  };
  return (
    <div
      className={`margin-top-16 ${classes.box}`}
      style={{ backgroundColor: color }}
    >
      {show && (
        <div className={classes.container}>
          <div
            className={`${classes.content} ${
              (titleLink || action) && 'pointer'
            }`}
            onClick={() => {
              if (action) {
                if (action === 'openPollsLink') {
                  return window.open(building.pollsLink);
                } else if (action === 'openRafflesLink') {
                  return window.open(building.rafflesLink);
                }
                setShowModal(true);
              } else {
                titleLink && history.push(titleLink);
              }
            }}
          >
            <div className={classes.textContainer}>
              <div
                className={
                  isSentiment ? classes.commentSentimentTitle : classes.title
                }
                style={{ color }}
              >
                {title}
              </div>
              {description && (
                <div className={classes.description}>{description}</div>
              )}
            </div>
            {renderValueField()}
          </div>
          {renderCustomFooter && (
            <div
              style={styles?.footerStyle}
              className={`${classes.footer} pointer`}
            >
              {renderCustomFooter()}
            </div>
          )}
          {(showActions === undefined ||
            (showActions != undefined && showActions)) &&
            footerLinkText && (
              <div
                style={styles?.footerStyle}
                className={`${classes.footer} pointer`}
                onClick={() => {
                  if (action) {
                    if (action === 'openPollsLink') {
                      window.open(building.pollsLink);
                    } else {
                      setShowModal(true);
                    }
                  } else {
                    footerLink && history.push(footerLink);
                  }
                }}
              >
                <div>{footerLinkText}</div>
                <LongRightArrow />
              </div>
            )}
          {action === 'showBUModal' && (
            <GenericModal
              showModal={showModal}
              title={'Building User'}
              content={
                <div>
                  <div>
                    <p>
                      By adding email, user will be able to comment and reply to
                      posts
                    </p>
                  </div>
                  <div>
                    <label>Email Address</label>
                    <CobuSelect
                      options={buildingUserOptions ? buildingUserOptions : []}
                      onChange={(selectedOption: SelectedOptionType | null) => {
                        setEmail(selectedOption?.value || '');
                        setError(false);
                        if (selectedOption?.value) {
                          setDisableActionButton(false);
                        } else {
                          setDisableActionButton(true);
                        }
                      }}
                      placeholder=''
                      selectType={SelectType.Form}
                      isClearable
                      noOptionsMessage='Not found. User must exist in the Building.'
                    />
                  </div>
                </div>
              }
              actionText={'Submit'}
              actionFunction={() => updateCommenter()}
              onChange={() => {
                setShowModal(false);
                setError(false);
                setEmail(
                  (currentUserRole === Roles.Superadmin ||
                  currentUserRole === Roles.Vcm
                    ? building.VCMCommenter
                    : building.PMCommenter) || ''
                );
              }}
              actionButtonDisabled={disableActionButton}
            />
          )}
          {action === 'showOUModal' && (
            <GenericModal
              showModal={showModal}
              title={'Edit Total Occupied Units'}
              content={
                <div>
                  <p className={`${classes.label} margin-top-16`}>
                    Total Occupied Units
                  </p>

                  <input
                    type='number'
                    id='totalOccupiedUnits'
                    name='totalOccupiedUnits'
                    value={totalOccupiedUnits}
                    onChange={(e) => {
                      if (
                        e.target.value !== '' &&
                        (Number(e.target.value) <= 0 ||
                          !Number.isInteger(Number(e.target.value)))
                      ) {
                        setError(true);
                      } else {
                        setError(false);
                      }
                      setTotalOccupiedUnits(e.target.value);
                    }}
                    className={`${classes.input} ${error && 'border-red'}`}
                  />
                </div>
              }
              actionText={'Submit'}
              actionFunction={() => {
                !error &&
                  Number.isInteger(Number(totalOccupiedUnits)) &&
                  updateOccupiedUnits &&
                  updateOccupiedUnits(Number(totalOccupiedUnits));
                setShowModal(false);
              }}
              onChange={() => {
                setShowModal(false);
                setError(false);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    userRole: state.auth.userRole
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    setCommenterSuccess
  };
}

export default connect(mapStateToProps, mapDispatchToProps())(StatsBox);
