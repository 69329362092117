import { AxiosResponse } from 'axios';
import {
  QuestionsTypes,
  QuestionSubType
} from '~/containers/Questionnair/constants';
import { ISelectOption } from '~/helpers/interfaces';
import { emperor } from '~/services';
import { UserMin } from '~/types/user';

export interface IQuestionnair<T = IQuestionWithAnswer[]> {
  uuid: string;
  building: string;
  name: string;
  shortcode: string;
  initial_question: string;
  createdAt: string;
  updatedAt: string;
  showQuestions: boolean;
  questions?: T;
  isAlreadyRated: boolean;
}

export interface IQuestionWithAnswer {
  uuid: string;
  question: string;
  answer: string;
  score?: number;
  type?: QuestionsTypes;
}

export interface IQuestionnaireQuestion {
  question: string;
  priority: number;
  type?: QuestionsTypes;
  subType?: QuestionSubType;
}

export interface IQuestionTypeAndSubType extends ISelectOption {
  subTypes: { label: string; value: string }[];
}

export interface IAppStoreLinks {
  ios: string;
  android: string;
}

export interface IQuestionnaireWithResult extends Partial<IQuestionnair> {
  results: string;
  numberOfQuestions: string;
  scheduledDetails?: { schedule_time: string; meta: any };
}

export const fetchQuestionnairAndUserFromCode = async (
  questionnairCode: string,
  userCode: string
): Promise<AxiosResponse<{ questionnair: IQuestionnair; user: UserMin }>> => {
  return await emperor.get('/questionnair/questionnair-and-user-from-code', {
    params: { questionnairCode, userCode }
  });
};

export const fetchQuestionnairQuestionWithUserAnswer = async (
  questionnairUuid: string,
  userUuid: string
): Promise<AxiosResponse<IQuestionWithAnswer[]>> => {
  return await emperor.get('/questionnair/questions-with-answers', {
    params: { questionnairUuid, userUuid }
  });
};

export const postUserAnswerForQuestion = async (
  questionnairUuid: string,
  questionUuid: string,
  userUuid: string,
  answer: string | number
): Promise<AxiosResponse<UserMin>> => {
  return await emperor.post('/questionnair/user-answer', {
    questionnairUuid,
    questionUuid,
    userUuid,
    answer
  });
};

export const postQuestionnairRating = async (
  questionnairUuid: string,
  userUuid: string,
  rating: number
): Promise<AxiosResponse<IAppStoreLinks>> => {
  return await emperor.post('/questionnair/user-rating', {
    questionnairUuid,
    userUuid,
    rating
  });
};

export const getQuestionTypeAndSubTypes = async (): Promise<
  AxiosResponse<IQuestionTypeAndSubType[]>
> => {
  return await emperor.get('/questionnair/question-types-and-sub-types');
};

export const postBuildingQuestionnair = async (
  name: string,
  buildingId: string,
  questions: IQuestionnaireQuestion[]
): Promise<AxiosResponse<IQuestionnair>> => {
  return await emperor.post('/questionnair/create-questionnair', {
    name,
    building: buildingId,
    questions
  });
};

export const patchBuildingQuestionnair = async (
  name: string,
  buildingId: string,
  questionnaireId: string,
  questions: IQuestionnaireQuestion[]
): Promise<AxiosResponse<IQuestionnair>> => {
  return await emperor.patch('/questionnair/patch-questionnair', {
    name,
    building: buildingId,
    queationnaireUuid: questionnaireId,
    questions
  });
};

export const getBuildingQuestionnaireWithResults = async (
  buildingId: string
): Promise<AxiosResponse<IQuestionnaireWithResult[]>> => {
  return await emperor.get('/questionnair/building-result', {
    params: { buildingId }
  });
};

export const getQuestionnairWithPriority = async (
  questionnaireId: string
): Promise<AxiosResponse<IQuestionnair<IQuestionnaireQuestion[]>>> => {
  return await emperor.get('/questionnair/questions-with-priority', {
    params: { questionnaireId }
  });
};
