import React from "react";
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs";
import Spacer from "~/components/Spacer/Spacer";
import Spinner from "~/components/Spinner/Spinner";
import useLeadAudits from "~/hooks/audits/useLeadAudits";
import styles from "../../ActiveSurveys/ActiveSurvey.module.css";
import { LeadsAudit } from "~/types/audit";
import CobuConstants from "~/helpers/cobuConstants";
import CobuTableAdvanced from "~/components/CobuTableAdvanced/CobuTableAdvanced";
import { CellProps } from "react-table";
import moment from "moment";
import BooleanBox from "~/components/BooleanBox/BooleanBox";

const LeadAudits: React.FC = () => {
    const { loadingConfig, leadsAuditDetails } = useLeadAudits();

    if (loadingConfig.flag) {
        return (
            <div className={styles.loadingContainer}>
                <Spinner />
                <h3 style={{ textAlign: "center" }}>{loadingConfig.comment}</h3>
            </div>
        );
    }

    const auditTableConfig = CobuConstants.leadsAudit.tableConfig.map((column: any) => ({
        ...column,
        Cell: (cell: CellProps<LeadsAudit>) => {
            switch (column.id) {
                case "theme":
                    return (
                        <div className="default-text">
                            {cell.row.original.theme
                                ? cell.row.original.theme
                                    .split("-")[0]
                                    .toLocaleLowerCase()
                                    .replace(/^./, (char) => char.toUpperCase())
                                : CobuConstants.leadsAudit.defaultText}
                        </div>
                    );
                case "createdAt":
                    return (
                        <div>
                            {moment(cell.row.original.createdAt)
                                .local()
                                .format(CobuConstants.leadsAudit.dateFormat)}
                        </div>
                    );
                case "helloDataPropertyId":
                case "isReferralHidden":
                case "isPepEnabled":
                case "hasEmployee":
                case "hidePerks":
                    return (
                        <BooleanBox
                            value={
                                column.id === "hidePerks"
                                    ? !(cell.row.original[column.id as keyof LeadsAudit] as boolean)
                                    : !!cell.row.original[column.id as keyof LeadsAudit]
                            }
                        />
                    );
                default:
                    return (
                        <div className="default-text">
                            {cell.row.original[column.id as keyof LeadsAudit] || CobuConstants.leadsAudit.defaultText}
                        </div>
                    );
            }
        },
    }));

    return (
        <div className="content-container">
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>{CobuConstants.leadsAudit.pageTitle}</h1>
            <Spacer height={20} />
            <CobuTableAdvanced
                columns={auditTableConfig}
                data={leadsAuditDetails}
                noData={{
                    label: CobuConstants.leadsAudit.noDataPlaceholder,
                    action: undefined,
                    image: undefined,
                }}
                actionsStyle={{ top: "150px" }}
                search={{
                    searchField: 'name',
                    placeholder: 'Name'
                }}
            />
        </div>
    );
};

export default LeadAudits;