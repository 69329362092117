import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { toast } from 'react-toastify';
import {
  getQuestionnairWithPriority,
  getQuestionTypeAndSubTypes,
  IQuestionnair,
  IQuestionnaireQuestion,
  IQuestionTypeAndSubType,
  patchBuildingQuestionnair,
  postBuildingQuestionnair
} from '~/api/questionnair';
import { ReactComponent as EditIcon } from '~/utils/images/grid-edit.svg';
import { ReactComponent as TrashIcon } from '~/utils/images/trash.svg';
import { IQuestionnairTableQuestion } from './interfaces';

export default (buildingId: string, questionnaireId: string) => {
  const [questionTypeAndSubTypes, setQuestionTypeAndSubTypes] = useState<
    IQuestionTypeAndSubType[]
  >([]);
  const [draftedQuestions, setDraftedQuestions] = useState<
    IQuestionnairTableQuestion[]
  >([]);
  const [selectedQuestion, setSelectedQuestion] =
    useState<IQuestionnairTableQuestion | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showQuestionForm, setShowQuestionForm] = useState<boolean>(false);
  const [questionnaireName, setQuestionnaireName] = useState<string>('');
  const history = useHistory();

  const fetchEditingQuestionnaire = async () => {
    try {
      setIsLoading(true);

      const { data } = await getQuestionnairWithPriority(questionnaireId);

      const { questions, name } = data;

      setIsLoading(false);
      setQuestionnaireName(name);
      setDraftedQuestions(questions as IQuestionnairTableQuestion[]);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const fetchQuestionTypeAndSubTypes = async () => {
    try {
      setIsLoading(true);

      const { data } = await getQuestionTypeAndSubTypes();

      setIsLoading(false);
      setQuestionTypeAndSubTypes(data);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onAddNewQuestion = () => {
    setSelectedQuestion(null);
    setShowQuestionForm(true);
  };

  const onAppendDraftedQuestion = (question: IQuestionnairTableQuestion) => {
    let priority = 0;

    const newQuestions = draftedQuestions.map((draftedQuestion) => {
      if (draftedQuestion.isDeleted) {
        return draftedQuestion;
      }

      priority++;
      draftedQuestion.priority = priority;

      return draftedQuestion;
    });

    const foundQuestionIndex = newQuestions.findIndex(
      (ques) => ques.id === question.id
    );

    console.log(newQuestions, foundQuestionIndex, '&&&***', question);

    const newQuestion = {
      ...(foundQuestionIndex >= 0 ? newQuestions[foundQuestionIndex] : {}),
      ...question
    };

    const selectedQuestionType = questionTypeAndSubTypes.find(
      (questionType) =>
        questionType.value === (newQuestion.type as unknown as string)
    );

    if (selectedQuestionType) {
      newQuestion.typeLabel = selectedQuestionType.label;
      const selectedQuestionSubType = selectedQuestionType.subTypes?.find(
        (type) => type.value === (newQuestion.subType as unknown as string)
      );

      if (selectedQuestionSubType) {
        newQuestion.subTypeLabel = selectedQuestionSubType.label;
      }
    }

    if (foundQuestionIndex >= 0) {
      newQuestions.splice(foundQuestionIndex, 1, newQuestion);

      setShowQuestionForm(false);
      setSelectedQuestion(null);

      setDraftedQuestions(newQuestions);

      return;
    }

    newQuestion.priority = newQuestions.length + 1;

    newQuestions.push(newQuestion);

    setShowQuestionForm(false);
    setDraftedQuestions(newQuestions);
    setSelectedQuestion(null);
  };

  // post survery
  const postQuestionnair = async () => {
    if (draftedQuestions) {
      try {
        setIsLoading(true);

        await postBuildingQuestionnair(
          questionnaireName,
          buildingId,
          draftedQuestions
        );

        setIsLoading(false);

        toast('Questionnaire Created Successfully');

        history.goBack();
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  //patch survey
  const patchQuestionnair = async () => {
    if (draftedQuestions) {
      try {
        setIsLoading(true);

        await patchBuildingQuestionnair(
          questionnaireName,
          buildingId,
          questionnaireId,
          draftedQuestions
        );

        toast('Survey patched successfully');

        setIsLoading(false);

        fetchEditingQuestionnaire();
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const onSortDraftQuestions = (sortedQuestions: Record<any, any>[]) => {
    let priority = 0;

    const newQuestions = sortedQuestions.map((draftedQuestion) => {
      if (draftedQuestion.isDeleted) {
        return draftedQuestion;
      }

      priority++;
      draftedQuestion.priority = priority;

      return draftedQuestion;
    });

    setDraftedQuestions(newQuestions as IQuestionnairTableQuestion[]);
  };

  const onEditQuestionForm = (question: IQuestionnairTableQuestion) => {
    setSelectedQuestion(question);
    setShowQuestionForm(true);
  };

  const onDeleteQuestion = (question: IQuestionnairTableQuestion) => {
    if (window.confirm('Are you sure you want to remove this question?')) {
      const questionIndex = draftedQuestions.findIndex(
        (q) => q.id === question.id
      );

      if (questionIndex >= 0) {
        let priority = 0;

        const newQuestions = draftedQuestions.map((draftedQuestion, index) => {
          console.log(questionIndex, index, '****** delte@@');
          if (index === questionIndex) {
            draftedQuestion.isDeleted = true;

            return draftedQuestion;
          }

          priority++;
          draftedQuestion.priority = priority;

          return draftedQuestion;
        });

        setDraftedQuestions(newQuestions);
      } else {
        toast('Please select question again. Question not found');
      }
    }
  };

  const onCancelQuestionForm = () => {
    setSelectedQuestion(null);
    setShowQuestionForm(false);
  };

  const columns: Column<any>[] = [
    {
      Header: 'QUESTION',
      accessor: 'question',
      Cell: (cell: CellProps<IQuestionnairTableQuestion>) => {
        return <div>{cell.value || '--'}</div>;
      },
      disableSortBy: true
    },
    {
      Header: 'TYPE',
      accessor: 'type',
      Cell: (cell: CellProps<IQuestionnairTableQuestion>) => {
        return <div>{cell.row.original.typeLabel || cell.value || '--'}</div>;
      },
      disableSortBy: true
    },
    {
      Header: 'SUB TYPE',
      accessor: 'subType',
      Cell: (cell: CellProps<IQuestionnairTableQuestion>) => {
        return (
          <div>{cell.row.original.subTypeLabel || cell.value || '--'}</div>
        );
      },
      disableSortBy: true
    },
    {
      Header: 'ACTIONS',
      accessor: '',
      Cell: (cell: CellProps<IQuestionnairTableQuestion>) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0 20px'
          }}
        >
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => onEditQuestionForm(cell.row.original)}
          >
            <EditIcon />
          </div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => onDeleteQuestion(cell.row.original)}
          >
            <TrashIcon />
          </div>
        </div>
      ),
      disableSortBy: true
    }
  ];

  const onCancelCreateEdit = () => {
    if (
      window.confirm(
        'You are about to loose your changes, are you sure you want to leave this page?'
      )
    ) {
      history.goBack();
    }
  };

  useEffect(() => {
    if (questionnaireId) {
      fetchEditingQuestionnaire();
    }

    fetchQuestionTypeAndSubTypes();
  }, []);

  return {
    isLoading,
    columns,
    showQuestionForm,
    questionTypeAndSubTypes,
    draftedQuestions: draftedQuestions.filter(
      (question) => !question.isDeleted
    ),
    selectedQuestion,
    questionnaireName,
    onSortDraftQuestions,
    onAddNewQuestion,
    onCancelQuestionForm,
    onAppendDraftedQuestion,
    postQuestionnair,
    patchQuestionnair,
    setQuestionnaireName,
    onCancelCreateEdit
  };
};
