import React from "react";
import "./BooleanBox.css"; 

interface BooleanBoxProps {
  value: boolean;
}

const BooleanBox: React.FC<BooleanBoxProps> = ({ value }) => {
  return (
    <div className={`boolean-box ${value ? "boolean-box--true" : "boolean-box--false"}`}>
      {value ? "✓" : "x"}
    </div>
  );
};

export default BooleanBox;