import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getConfigAudit } from "~/api/audits"; 
import CobuConstants from "~/helpers/cobuConstants";
import { ConfigAudit } from "~/types/audit";

interface LoadingConfig {
    flag: boolean;
    comment?: string;
}

const useConfigAudits = () => {
    const [configAuditDetails, setConfigAuditDetails] = useState<ConfigAudit[]>([]);
    const [loadingConfig, setLoadingConfig] = useState<LoadingConfig>({
        flag: false,
        comment: "",
    });

    useEffect(() => {
        fetchConfigAuditDetails();
    }, []);

    const fetchConfigAuditDetails = async (refresh: boolean = false) => {
        setLoadingConfig({
            flag: true,
            comment: refresh
                ? CobuConstants.audits.refreshingAuditDetails
                : CobuConstants.audits.fetchingAuditDetails,
        });

        try {
            const auditResp = await getConfigAudit();
            setConfigAuditDetails(auditResp.data.reverse());
            setLoadingConfig({ flag: false });
        } catch (error: any) {
            setLoadingConfig({ flag: false });
            toast(error?.message || CobuConstants.audits.fetchError);
        }
    };

    return {
        configAuditDetails,
        loadingConfig,
        fetchConfigAuditDetails,
    };
};

export default useConfigAudits;