import {
  FormInputConfig,
  ResidentInputFieldConfig
} from '~/types/residentManagement';
import CobuConstants from './cobuConstants';
import {
  nameRegex,
  emailRegex,
  phoneRegex,
  uriRegex,
  numberRegex
} from './helpers';

export const getCreateUserFormConfig = (
  isPmUser = false
): ResidentInputFieldConfig[] => {
  return [
    {
      label: CobuConstants.createResident.firstName,
      id: 'firstName',
      pattern: nameRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.createResident.namePatternError
    },
    {
      label: CobuConstants.createResident.lastName,
      id: 'lastName',
      pattern: nameRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.createResident.namePatternError
    },
    {
      label: CobuConstants.createResident.email,
      id: 'email',
      pattern: emailRegex(),
      patternErrorMsg: CobuConstants.createResident.emailPatternError
    },
    ...(!isPmUser
      ? [
          {
            label: CobuConstants.createResident.phone,
            id: 'phone',
            pattern: phoneRegex(),
            patternErrorMsg: CobuConstants.createResident.phonePatternError
          },
          {
            label: CobuConstants.createResident.roomNumber,
            id: 'roomNumber'
          }
        ]
      : []),
    {
      label: CobuConstants.createResident.password,
      id: 'password',
      minLength: 6,
      minLengthError: CobuConstants.createResident.passwordMinLengthError
    }
  ];
};

export const getEditUserFormConfig = (): ResidentInputFieldConfig[] => {
  return [
    {
      label: CobuConstants.createResident.firstName,
      id: 'firstName',
      pattern: nameRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.createResident.namePatternError
    },
    {
      label: CobuConstants.createResident.lastName,
      id: 'lastName',
      pattern: nameRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.createResident.namePatternError
    },
    {
      label: CobuConstants.createResident.email,
      id: 'email',
      pattern: emailRegex(),
      patternErrorMsg: CobuConstants.createResident.emailPatternError
    },
    {
      label: CobuConstants.createResident.displayName,
      id: 'displayName',
      readOnly: true
    },
    {
      label: CobuConstants.createResident.phone,
      id: 'phone',
      pattern: phoneRegex(),
      patternErrorMsg: CobuConstants.createResident.phonePatternError
    },
    {
      label: CobuConstants.createResident.roomNumber,
      id: 'roomNumber'
    },
    {
      label: CobuConstants.createResident.password,
      id: 'password',
      minLength: 6,
      minLengthError: CobuConstants.createResident.passwordMinLengthError
    }
  ];
};

export const getCreateSurveyFormConfig = (): ResidentInputFieldConfig[] => {
  return [
    {
      label: CobuConstants.createSurvey.title,
      id: 'name',
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError
    },
    {
      label: CobuConstants.createSurvey.description,
      id: 'description'
    },
    {
      label: CobuConstants.createSurvey.surveyUrl,
      id: 'surveyURL',
      pattern: uriRegex(),
      patternErrorMsg: CobuConstants.createResident.uriPatternError
    },
    {
      label: CobuConstants.createSurvey.callToAction,
      id: 'callToAction'
    }
  ];
};

export const getCreateTooltipFormConfig = (): ResidentInputFieldConfig[] => {
  return [
    {
      label: CobuConstants.createTooltip.title,
      id: 'title',
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError
    },
    {
      label: CobuConstants.createTooltip.description,
      id: 'description'
    },
    {
      label: CobuConstants.createTooltip.callToAction,
      id: 'callToAction'
    }
  ];
};

export const externalURLFormConfig = {
  label: CobuConstants.createTooltip.surveyUrl,
  id: 'linkURL',
  pattern: uriRegex(),
  patternErrorMsg: CobuConstants.createResident.uriPatternError
};

export const getGoogleSettingFormConfig = (): FormInputConfig[] => {
  return [
    {
      label: CobuConstants.editGoogleReviewSettings.minSentimentScoreLabel,
      id: 'minsentimentscore',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label: CobuConstants.editGoogleReviewSettings.hourOfDayLabel,
      id: 'hourofday',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings.recentActivityThresholdLabel,
      id: 'recentactivitydaythreshold',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings.oldActivityMonthThresholdLabel,
      id: 'oldactivitymonththreshold',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label: CobuConstants.editGoogleReviewSettings.activeUserDayThresholdLabel,
      id: 'activeuserdaythreshold',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label: CobuConstants.editGoogleReviewSettings.reviewQuotaDivisorLabel,
      id: 'reviewquotadivisor',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label: CobuConstants.editGoogleReviewSettings.maxReviewsPerDayLabel,
      id: 'maxreviewrequestsperday',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings.invitationSentWithinDaysLabel,
      id: 'invitationsentwithindays',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings
          .eligibleResidentActivityDaysLabel,
      id: 'eligibleresidentactivitydays',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings
          .eligibleResidentCreatedWithinDaysLabel,
      id: 'eligibleresidentcreateddatedays',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings.eligibleGroupCommentMonthsLabel,
      id: 'eligiblegroupcommentsmonths',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings.allowedNegativeCommentsLabel,
      id: 'allowednegitivecomments',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings
          .recentCommentScoreCalculationMultiplierLabel,
      id: 'multiplierrecenetcomments',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings
          .oldCommentScoreCalculationMultiplierLabel,
      id: 'multiplieroldcomments',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings
          .recentGreatCommentScoreCalculationMultiplierLabel,
      id: 'multiplierrecentgreatcomments',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings
          .oldGreatCommentScoreCalculationMultiplierLabel,
      id: 'multiplieroldgreatcomments',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings
          .recentRSVPsScoreCalculationMultiplierLabel,
      id: 'multiplierrecentrsvps',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    },
    {
      label:
        CobuConstants.editGoogleReviewSettings
          .oldRSVPsScoreCalculationMultiplierLabel,
      id: 'multiplieroldrsvps',
      pattern: numberRegex(),
      maxLength: 25,
      maxLengthError: CobuConstants.createResident.nameMaxLengthError,
      patternErrorMsg: CobuConstants.editGoogleReviewSettings.numberPatternError
    }
  ];
};
