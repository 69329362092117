import React, { useState } from 'react';
import ActionDropDown from '~/components/ActionDropDown/ActionDropDown';
import { CreateEditBuildingForm } from '~/components/CreateEditBuildingForm/CreateEditBuildingForm';
import GenericModal from '~/components/GenericModal/GenericModal';
import { Colors } from '~/enums/Colors';
import AddDownArrowIcon from '~/utils/images/add-downArrow.svg';
import MenuIcon from '~/utils/images/menu.svg';
import { getCreateBuildingFormConfig, timezoneOptions } from './constants';
import CobuConstants from '~/helpers/cobuConstants';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/store';
import { Roles } from '~/enums/Roles';

const goToMap = new Map([
  ['UserManagement', `/building/admins`],
  ['PostManagement', `/building/pinned-post-management`],
  ['EventManagement', `/building/event-management`],
  ['GoogleReviewManagement', `/building/google-review-summary`],
  ['SurveyManagement', `/building/surveys`],
  ['TooltipManagement', `/building/tooltips`],
  ['OwnershipPMManagement', `/building/ownerManagement`],
  ['PropertyAssignments', `/building/properyAssignment`],
  ['PMSIntegration', `/pms-integrations`],
  ['UserTenantMatching', `/tenant-management/tenant-matching`],
  ['FlyersManagement', `/building/flyerManagement`],
  ['BuildingKeys', `/building/buildingKeys`],
  ['EventReminder', `/building/eventReminder`],
  ['Audits', `/building/audits`],
  ['Questionnaires', `/building/cobu/questionnaires`]
]);

export const CreateMenu = () => {
  const history = useHistory();

  const [showBuildingForm, setShowBuildingForm] = useState(false);

  const role = useSelector((state: RootState) => {
    return state.auth.userRole;
  });

  const toggleShowBuildingForm = () => {
    setShowBuildingForm((prevValue) => !prevValue);
  };

  const goto = (name: string) => {
    history.push(goToMap.get(name));
  };

  return (
    <>
      <ActionDropDown
        title={'Tools'}
        showIcon
        icon={<img src={MenuIcon} style={{ width: 40, height: 35 }} />}
        optionStyles={{ color: Colors.cobuDarkBlue }}
        containerStyles={{
          backgroundColor: Colors.cobuDarkBlue,
          borderColor: Colors.cobuDarkBlue,
          flexDirection: 'column',
          zIndex: 99
        }}
        options={[
          {
            label: 'User Management',
            name: 'UserManagement',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'Post Management',
            name: 'PostManagement',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'Event Management',
            name: 'EventManagement',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'Survey Management',
            name: 'SurveyManagement',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'Tooltip Management',
            name: 'TooltipManagement',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'Google Review Management',
            name: 'GoogleReviewManagement',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'Ownership / PM Management',
            name: 'OwnershipPMManagement',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'Property Assignments',
            name: 'PropertyAssignments',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'PMS Integrations',
            name: 'PMSIntegration',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'User-Tenant Matching',
            name: 'UserTenantMatching',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'Flyers Management',
            name: 'FlyersManagement',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'Building Keys',
            name: 'BuildingKeys',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'Lack Of Event Reminder',
            name: 'EventReminder',
            action: function () {
              goto(this.name!);
            }
          },
          {
            label: 'Audits',
            name: 'Audits',
            action: function () {
              goto(this.name!);
            }
          },
          ...(role === Roles.Superadmin
            ? [
                {
                  label: 'Cobu Questionnaires',
                  name: 'Questionnaires',
                  action: function () {
                    goto(this.name!);
                  }
                }
              ]
            : [])
        ]}
      />

      <ActionDropDown
        title={'Create'}
        showIcon
        icon={<img src={AddDownArrowIcon} style={{ width: 40, height: 35 }} />}
        optionStyles={{ color: Colors.cobuDarkBlue }}
        containerStyles={{
          backgroundColor: Colors.cobuDarkBlue,
          borderColor: Colors.cobuDarkBlue,
          flexDirection: 'column'
        }}
        options={[
          {
            label: 'Building',
            action: toggleShowBuildingForm
          }
        ]}
      />
      <GenericModal
        showModal={showBuildingForm}
        title={'Create Building'}
        enableOverflowScrolls
        content={
          showBuildingForm ? (
            <CreateEditBuildingForm
              formConfig={getCreateBuildingFormConfig(timezoneOptions)}
              onCancel={toggleShowBuildingForm}
            />
          ) : null
        }
        hideActionButton
        canceActionButton
        actionText={CobuConstants.createBuilding.createActionTitle}
        actionFunction={() => {}}
        onChange={() => {}}
      />
    </>
  );
};
